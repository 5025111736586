'use strict';

angular.module('enervexSalesappApp').controller('CompaniesCtrl', function($scope, $stateParams, Account, Company) {
	$scope.accountId = $stateParams.accountId;
	Account.get({
		id: $stateParams.accountId
	}).$promise.then(function(res){
		$scope.account = res;
	});
	fetchCompanies();
	function fetchCompanies(){
		Company.query({
			accountId: $stateParams.accountId
		}).$promise.then(function(companies){
			$scope.companies = companies;
		});
	}
});
